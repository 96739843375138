<template>
    <nav class="navbar navbar-expand navbar-light fixed-top">
      <div class="container">
        <router-link to="MyHome" class="navbar-brand">CYBERPUERTA</router-link>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav ml-auto" v-if="!me">
            <li class="nav-item">
              <router-link to="MyNewLogin" class="nav-link">Iniciar Sesión</router-link>
              </li>
              <!--<li class="nav-item">
                <router-link to="MyRegister" class="nav-link">Registrarse</router-link>
              </li>-->
          </ul>

          <ul class="navbar-nav ml-auto" v-if="me">
            <li class="nav-item">
              <router-link to="MiCRUD" class="nav-link">CRUD</router-link>
              </li>    
              <li class="nav-item">
              <router-link to="MisWaybills" class="nav-link">Guías</router-link>
              </li>
              <li class="nav-item">
              <router-link to="MisVentas" class="nav-link">Ordenes de Compra</router-link>
              </li>
              <li class="nav-item">
              <router-link to="ConsumoApi" class="nav-link">Agregar Producto</router-link>
              </li>       
               <li class="nav-item">
              <router-link to="ActualizarWS" class="nav-link">Actualizar WS</router-link>
              </li>
            <li class="nav-item">
              <a href="javascript:void(0)" @click="handleClick" class="nav-link">Cerrar Sesión</a>
              </li>
          </ul>

        </div>
      </div>
    </nav>
    </template>
    
    <script>

import {mapGetters} from 'vuex';
      export default {
        name: 'MyNav',
        methods: {

          handleClick(){

            localStorage.removeItem('access_token');
            this.$store.dispatch('me', null);
            this.$router.push('/MyHome');
          }
        },
          computed: {

            ...mapGetters(['me'])
          }
      }
    </script>